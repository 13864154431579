
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useEffect, useRef, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import esLocale from '@fullcalendar/core/locales/es';
import CaminanteSelectorModal from './CaminanteSelectorModal'
import axios from 'axios'
import Swal from 'sweetalert2';


export default function Calendardisp(params) {
  const calendar = useRef();
  const [semana, setSemana] = useState('');
  const [datoreserva, setDatoreserva] = useState(null)
  const [caminares, setCaminares] = useState(null)
  const [disponibilidad, setDisponibilidad] = useState(null)
  const guia = params.codguia.CODGUIA
  const diff = (new Date().getTimezoneOffset() / 60)

  const getCaminares = async (codguia) => {
    await axios.get('https://calendario.caminantes.cl/caminares/' + codguia).then((response => {
      let data = response.data.data
      data.forEach(c => {
        c.start = new Date(c.start)
      });
      setCaminares(data)
    }))

  }
  const getDisponibilidad = async (codguia) => {
    await axios.get('https://calendario.caminantes.cl/disponibilidad/' + codguia)
      .then((response => {
        let data = response.data.data
        setDisponibilidad(data)
      }
      ))
  }
  function getWeekDay(locale, date) {
    return (date.toLocaleDateString(locale, { weekday: 'long' }))
  }
  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1).toLowerCase();
  }
  const solicita = (ev) => {
    let datos = {}
    datos.diasem = capitalizeFirstLetter(getWeekDay(esLocale, new Date(ev.event.start)))
    datos.hora = new Date(ev.event.start).toLocaleTimeString()
    datos.dia = new Date(ev.event.start).toLocaleDateString()
    datos.start = new Date(ev.event.start.setHours(new Date(ev.event.start).getHours()-diff))
    datos.end = new Date(ev.event.end.setHours(new Date(ev.event.end).getHours()-diff))
    datos.codguia = ev.event.extendedProps.codguia;
    datos.nomguia = ev.event.extendedProps.nomguia
    datos.servicio = disponibilidad[0].SERVICIO;
    datos.cuadro =
      `<div>${datos.nomguia}<br>${datos.diasem}<br>${datos.dia}</div><div>Hora: ${datos.hora}</div><div>${datos.servicio}</div>`
    document.getElementById('nombreprofesion').innerHTML = '';
    document.getElementById('datosCaminar').classList = 'h-40 bg-white text-black text-xg text-sm w-fit h-fit p-2 rounded-md '
    document.getElementById('datosCaminar').innerHTML = datos.cuadro;
    setDatoreserva(datos)
  }
  const recibeSelected = (camnte) => {
    if (camnte.codcaminante === 0) {
      setDatoreserva(null)
    }
    else {
      let datos = datoreserva
      datos.nomcaminante = capitalizeFirstLetter(camnte.nomcaminante)
      datos.nomguia = capitalizeFirstLetter(datoreserva.nomguia)
      datos.codcaminante = camnte.codcaminante
      datos.email = camnte.email
      datos.codcaminante = camnte.codcaminante
      setDatoreserva(datos)
      confirmaSolicitud()
    }
    params.funcion(false)
  }
  function confirmaSolicitud() {
    if (datoreserva !== null) {
      enviaSolicitudHora(datoreserva)
      setDatoreserva(null)
    }
  }
  function enviaSolicitudHora(datores) {
    const postSolicitud = async () => {
      if (datores.codcaminante) {
        try {
          const newsol = await axios.post("https://calendario.caminantes.cl/newsolicitud", {
            method: "POST",
            mode: "no-cors",  // Change the mode to CORS
            params: {
              codguia: datores.codguia,
              nomguia: datores.nomguia,
              email: datores.email,
              codcaminante: datores.codcaminante,
              rutcaminante: datores.rutcaminante,
              dia: datores.dia,
              hora: datores.hora,
              start: datores.start,
              end: datores.end
            },
          })
          enviaMail(datores, newsol.data.data.insertId);
          // alert('Te hemos enviado un email con los datos de transferencia para que reserves tu hora de atención')
          const html = `
            <div className="font-bold text-xl text-left text-yellow-400">
            <p>Nombre: Centro de Terapias Caminantes</p>
            <p>Rut: 77.581.685-6</p>
            <p>Banco: Banco Estado</p>
            <p>Cuenta: Chequera Electrónica</p>
            <p>Número: 36072963401</p>
            <p>Email: centrocaminantes@caminantes.cl</p>
            </div>
          `

          const textToCopy = `
            Nombre: Centro de Terapias Caminantes
            Rut: 77.581.685-6
            Banco: Banco Estado
            Cuenta: Chequera Electrónica
            Número: 36072963401
            Email: centrocaminantes@caminantes.cl
          `;

          const copyToClipboard = (text) => {
            navigator.clipboard.writeText(text)
              .then(() => Swal.fire({ title: 'Copiado', position: 'top', timer: 1000 }))
              .catch(() => Swal.fire('Error', 'No se pudo copiar.', 'error'));
          };

          Swal.fire({
            title: 'Datos de transferencia para pago Caminantes:',
            position: 'top', // Appears at the top
            confirmButtonText: 'Copiar',
            confirmButtonColor: '#3085d6',
            html: html,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              copyToClipboard(textToCopy)
            }
          });
          ;
        } catch (error) {
          console.error(error);
        }
      }
    };
    postSolicitud();
    // setSemana('')
  }
  const enviaMail = async (datos, id) => {
    const Whatsapp =
      ' <a target="_blank"'
      + ' href="https://wa.me/56975114334?text='
      + 'Cod: ' + id
      + ' Hola Dolores, adjunto el comprobante de transferencia para reservar la sesión del : '
      + datos.dia
      + ' a las '
      + datos.hora
      + '"  rel="noreferrer">'
      + '<img width="200" src="https://caminantes.cl/Whatsapp.png" alt="wap" ></img>'
      + '</a>'
    const formData = new FormData();
    formData.append('email', datos.email);
    formData.append('codigo', id)
    formData.append('subject',
      'Completa tu solicitud de hora para: ' + datos.nomcaminante + ', el '
      + datos.diasem + ' '
      + datos.dia + ' '
      + datos.hora + ' '
      + '. Codigo: ' + id);
    formData.append('message',
      'Solicitaste una hora de '
      + datos.servicio
      + ' para '
      + datos.nomcaminante
      + ' con '
      + datos.nomguia
      + ' el '
      + datos.dia
      + ' a las '
      + datos.hora
      + '<p> Los datos de transferencia son: </p>'
      + '<p> Nombre: Centro de Terapias Caminantes </p>'
      + '<p> Rut: 77.581.685-6 </p>'
      + '<p> Banco Estado </p>'
      + '<p> Chequera Electrónica </p>'
      + '<p> 36072963401 </p>'
      + '<p> Email: centrocaminantes@caminantes.cl'
      + '<p> <p> Envíanos el comprobante de transferencia respondiendo este email </p>'
      + '<p> O por Whatsapp : </p>'
      + Whatsapp

    );
    const enviar = await fetch('https://php.caminantes.cl/mailconfirma.php', {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    });
    if (enviar) {
      setDatoreserva(null)
    }

  }
  const buildSemana = () => {
    if (calendar && disponibilidad && caminares) {
      let first = calendar.current.calendar.currentData.dateProfile.currentRange.start
      var semActual = [];
      var weekDispo = [];
      var inicio = new Date(first);//comienza el sábado
      for (let index = 0; index < 6; index++) {
        semActual.push({ fecha: new Date(inicio.setDate(inicio.getDate() + 1)), diasem: inicio.getDay() })
      }
      if (disponibilidad && caminares) {
        disponibilidad.forEach(d => {

          semActual.forEach((s) => {
            if (d.DIASEMANA === s.diasem && s.fecha > new Date()) {
              let start = new Date(s.fecha.setHours(d.start.substr(0, 2)) + diff)
              let end = new Date(s.fecha.setHours(d.end.substr(0, 2)) + diff)
              weekDispo.push({
                id: d.id,
                codguia: d.CODGUIA,
                start: start,
                end: end,
                title: d.NOMCORTOGUIA,
                nomguia: d.NOMGUIA
              })
            }
          })
        })

        caminares.forEach((c) => {
          weekDispo.push({
            id: c.id,
            codguia: guia,
            start: new Date(c.start),
            end: new Date(c.end),
            title: c.NOMCORTOGUIA,
            nomguia: c.NOMGUIA,
            extendedProps: { backgroundColor: '#000000' }
          })
        })
      }

      const startCounts = {};
      weekDispo.forEach(item => {
        startCounts[item.start] = (startCounts[item.start] || 0) + 1;
      });

      // Filter array to only include items with unique start times
      const uniqueStartItems = weekDispo.filter(item => startCounts[item.start] === 1 && item.nomguia !== undefined);


      setSemana(uniqueStartItems)
    }
  }
  function handleSalir() {
    setDisponibilidad(null)
    params.funcion(false)
  }
  useEffect(() => {
    if (guia) {
      getDisponibilidad(guia)
      getCaminares(guia)
      buildSemana()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {disponibilidad && caminares &&
        <div id='calendardisp' className=' andres rounded-md w-full' onLoad={buildSemana}>
          <div id='cabezalguia' className="flex flex-row h-32 text-xg andres relative 
          font-bold rounded-md ">
            <div className=' text-xl cursor-pointer  rounded-md  
              font-bold absolute top-2 right-2 text-center text-white bg-gray-600'
              onClick={handleSalir}>
              ✖️
            </div>
            <div id='foto' className="">
              <img className='rounded-xl w-32 p-1 lg:px-0 '
                src={'https://www.caminantes.cl/centro/images/guia/' + guia + '.jpg'}
                alt={guia}>
              </img>
            </div>
            <div id="datosSolicitud" className=" text-white text-left pt-1 h-fit">
              <div id='nombreprofesion' className='px-0 '>
                {params.selectedGuia.NOMGUIA}
                <br>
                </br>
                {params.selectedGuia.SERVICIO}
              </div>

              <div id='datosCaminar'>
              </div>

            </div>
          </div>
          <div className='py-1   ' >
            {datoreserva &&
              <div className='text-center'>
                <CaminanteSelectorModal datoreserva={datoreserva} funcion={recibeSelected} />
              </div>
            }
          </div>

          <div id='calendario' className='px-1 border-0'>
            <FullCalendar
              locale={esLocale}
              timeZone='local'
              bootstrapFontAwesome={true}
              customButtons={
                {
                  prev: {
                    text: 'Semana Anterior',
                    click: function () {
                      let finde = calendar.current.calendar.currentData.dateProfile.currentRange.start
                      let limite = ((new Date())) //restar diferencia en días entre now y finde
                      if (finde - limite > 0) {
                        calendar.current.calendar.prev()
                        buildSemana()
                      }
                    }
                  },
                  next: {
                    text: 'Semana Siguiente',
                    click: function () {
                      calendar.current.calendar.next()
                      buildSemana()
                    }
                  },
                  today: {
                    text: "-",
                    click: function () {
                      calendar.current.calendar.today()
                      buildSemana()
                    }
                  },
                }
              }
              ref={calendar}
              buttonHints={true}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
              dayHeaderFormat={{ weekday: 'short', day: 'numeric', month: 'long', omitCommas: true }}
              titleFormat={{ year: 'numeric', month: 'long', }}
              headerToolbar={{
                left: 'prev,today,next',
                center: 'title',
                right: ''
              }}
              buttonText={{
                today: '-',
                month: 'month',
                week: 'Semana',
                day: 'Día',
                list: 'list',
              }}
              initialView='timeGridWeek'
              height={'auto'}
              expandRows={true}
              hiddenDays={[0]}
              editable={true}
              selectable={true}
              weekends={true}
              displayEventTime={false}
              displayEventEnd={false}
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: false
              }}
              eventClick={solicita}
              events={semana}
              allDaySlot={false}
              dateClick={''}
              slotMinTime={'10:00:00'}
              slotDuration={'01:00:00'}
              slotMaxTime={'20:00:00'}
              eventTextColor='black'
              eventClassNames={[" text-xg rounded-sm bg-warning border-black font-bold text-center"]}
              viewClassNames={['text-black bg-blue']}
            />
          </div>
        </div>
      }
    </>)
}