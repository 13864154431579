import React from 'react'

export default function Guiadisplay(params) {
    function enviaGuia(){
        params.funcion(params.guia)
    }
    return (
    <>
        <div onClick={enviaGuia} 
        id="guia" className="cursor-pointer bg-violet-500 border-violet-100 border-2 rounded-lg lg:w-44 w-40 h-full">
            <div className="flex h-16 justify-center items-center">
                <div className="text-center">
                    <h1 className="pt-1 px-1 text-xl text-wrap text-white font-semibold">{params.guia.SERVICIO}</h1>
                </div>
            </div>
            <img className=' mx-auto rounded-lg w-40 pt-1 pb-1 h-40 px-1 '
                src={'https://www.caminantes.cl/centro/images/guia/' + params.guia.CODGUIA + ".jpg"}
                alt={params.guia.CODGUIA}>
            </img>
            <div className='pt-1 font-semibold text-xl text-white text-center'>{params.guia.NOMGUIA}</div>
            <div className="flex h-16 justify-center items-center">
                <div className="text-center">
                    <div className="text-2xl text-white px-1 text-wrap">{params.guia.PROFESION}</div>
                </div>
            </div>
        </div>
    </>
    )
}
