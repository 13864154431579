import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";



const CaminanteSelectorModal = (params) => {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [caminantes, setCaminantes] = useState([]);
    const [selectedCaminante, setSelectedCaminante] = useState(null);
    const [nielay, setNielay] = useState(null);
    const datoreserva = params.datoreserva

    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1).toLowerCase();
    }
    const handleClose = () => {
        setShow(false);
        setEmail("");
        setCaminantes([]);
        setSelectedCaminante(null);
        params.funcion(0)
        setNielay(null)
    };
    const getCaminante = async (email) => {
        await axios.get('https://calendario.caminantes.cl/caminante/' + email).then((response => {
            let data = response.data.data
            if (data.length > 0) {
                setCaminantes(data);
                setNielay(null)
                setShow(true)
            }
            else {
                setNielay('No hay caminantes asociados a este email. Solicita tu incorporación enviando tus datos al whatsapp +569978451335')
            }
        }))
    }
    const handleShow = () => setShow(true);

    const handleEmailSubmit = async (e) => {
        setSelectedCaminante('')
        setCaminantes('')
        e.preventDefault();
        getCaminante(email)
    };
    const handleCaminanteSubmit = (e) => {
        e.preventDefault();
        if (selectedCaminante) {
            params.funcion(selectedCaminante, false)
        } else {
            alert("Seleccione un Caminante");
        }
        setShow(false)
    };

    return (
        <>
            <div className="flex flex-col justify between text-black font-bold rounded-md" >{show}
                <div className="hidden border-0 border-black " id="botonSiguiente">
                    <Button variant="warning"
                        className="h-8 py-0 text-base font-semibold" onClick={handleShow}>
                        <div>Solicitar este horario</div>
                    </Button>
                </div>
                <div className="px-1">
                    <Form onSubmit={handleEmailSubmit}>
                        <Form.Group controlId="formEmail" className="pb-0" hidden={show}>
                            <Form.Label >
                                <div className="text-center text-sm text-white">Ingresa tu Email</div>
                            </Form.Label>
                            <Form.Control
                                className="font-bold text-xl pb-2"
                                type="email"
                                placeholder="ingrese email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value); setCaminantes('') }}
                                required
                            />
                            <div className="pt-2 px-1">
                                <Button type="submit" hidden={show} className="text-black font-bold pt-1 bg-warning" >
                                    Siguiente
                                </Button>
                            </div>
                            <div className=" text-base">
                                {'(En caso de niño/niña/adolescente, ingresa el email del adulto responsable)'}
                            </div>
                        </Form.Group>
                        <div className="pt-2">
                            {nielay &&
                                <a className="font-bold pt-0" target="_blank" href="https://wa.me/56975114334?text=Hola Dolores, te pido incorporarme a Caminantes, mi nombre rut y correo electrónico son: "
                                    rel="noreferrer">
                                    <img className="h-12  mx-auto border-2 rounded-lg" src="./Whatsapp.png" alt="wap" ></img>
                                    {nielay}
                                </a>
                            }
                        </div>
                    </Form>
                    <Form onSubmit={handleCaminanteSubmit} hidden={!show}>
                        <Form.Group controlId="formCaminante" >
                            <Form.Label >
                                <div className="text-center text-sm text-white">Datos del Caminante que asiste a la sesión</div>
                            </Form.Label>
                            <Form.Control
                                className="bg-warning text-xl font-bold rounded-md border-1 border-black pt-1"
                                as="select"
                                value={selectedCaminante ? selectedCaminante.codcaminante : ''}
                                onChange={(e) => {

                                    const selectedId = e.target.value;
                                    const caminante = caminantes.find((c) => c.codcaminante === parseInt(selectedId));
                                    if (caminante) {
                                        caminante.email = email
                                        setSelectedCaminante(caminante);
                                    }
                                }}
                                required
                            >
                                <option value={null} >Selecciona un Caminante</option>
                                {caminantes && caminantes.map((caminante) => (
                                    <option key={caminante.codcaminante} value={caminante.codcaminante}
                                        className="text-xl font-bold"
                                    >
                                        {capitalizeFirstLetter(caminante.nomcaminante)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div className="pt-2">
                            <div hidden={!selectedCaminante}
                                className=" text-left bg-warning border-1 rounded-md border-black text-xl font-bold px-2 ">
                                <div>{'Terapeuta: ' + datoreserva.nomguia}</div>
                                <div>{datoreserva.servicio}</div>
                                <div>{"Fecha: " + datoreserva.dia}</div>
                                <div>{"Hora: " + datoreserva.hora}</div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-1 justify-between hidden={!selectedCaminante}">
                            <Button
                                variant="warning" type="submit"
                                className="mt-2 px-2 border-1 border-black font-bold">
                                Envíanos tu solicitud
                            </Button>
                            <Button variant="info" onClick={handleClose}
                                className="hidden mt-2 px-2 border-1 border-black font-bold">
                                Salir
                            </Button>
                        </div>
                        <div className="pt-2 text-xl font-bold text-black" hidden={!selectedCaminante}>Te enviaremos un email para que completes la reserva </div>
                    </Form>
                </div>
            </div>



        </>
    );
};

export default CaminanteSelectorModal;

